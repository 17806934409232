import React from 'react'
import Layout from '../layouts'
import { Link } from 'gatsby'
import GBN from '../images/slide2.jpg'

const Genesis = () => (
	<Layout head={{ title: 'Certified Sales Leadership Mentoring and Coaching' }}>
		<div className="outsource">
			<div
				id="hero-section"
				className="bg-cover"
				style={{ background: `url("${GBN}")` }}
			>
				<div className="container">
					<div className="slim-content">
						<h1 className="text-red">
							Certified Sales Leadership Mentoring and Coaching
						</h1>

						<div>
							<a href="/contact-us" className="btn btn-primary red-btn">
								GET STARTED
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className="container py-5">
				<p>
					Are you the CEO or founder of a company looking for help developing a
					winning sales culture and a trained sales leadership team that
					achieves results?
				</p>
				<p>
					Are you the CEO who is running sales and marketing and can use outside
					coaching on current tools and best practices that increase your sales
					performance and growth?
				</p>
				<p>
					Are you an entrepreneur or startup hiring your first sales person or
					building your first sales team?
				</p>
				<p>
					Are you an up and coming sales leader looking for coaching or guidance
					on best practices?
				</p>
				<p>
					If you said to yes to any of these questions, please contact ProGrowth
					Advisors now for a free consultation at{' '}
					<a href="tel:512-696-1333" target="_blank" rel="noopener noreferrer">
						512-696-1333
					</a>{' '}
					or{' '}
					<a
						href="mailto:Joe@ProGrowthAdvisors.com"
						target="_blank"
						rel="noopener noreferrer"
					>
						Joe@ProGrowthAdvisors.com
					</a>
				</p>

				<p>
					ProGrowth Advisors leverages the Sales Xceleration Certified Sales
					Leader Program, along with experience building and leading world class
					sales teams, to mentor and coach you or your team to sales excellence
					and rapid growth.
				</p>
				<p>
					ProGrowth Advisor’s Founder Joe Dauskurdas is a Certified Sales Leader
					Trainer/Coach. The Certified Sales Leader (CSL) designation is the
					most comprehensive sales leadership certification program offered. Joe
					has over 28 years of sales and marketing leadership experience
					building and leading highly effective sales teams.
				</p>
				<center className="my-4">
					<Link to="/about" className="btn btn-primary red-btn">
						Meet The Founder
					</Link>
				</center>
				<div className="row mt-5">
					<div className="col-md-6 offset-md-3 align-self-center">
						<div className="sales-video">
							<iframe
								width="100%"
								height="304"
								title="SalesXceleration CSL Certification"
								src="https://www.youtube.com/embed/vgeYjqtWFdE"
								frameborder="0"
								allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
								allowfullscreen
							/>
						</div>
					</div>
				</div>
				<div className="text-center">
					<h3 className="my-5">
						We will develop a custom 1:1 Coaching/Mentoring program based on the
						business and leader’s needs. Sample Topics Include:
					</h3>
					<div className="gnsbs">
						<div className="row">
							<div className="col-md-6">
								<ul className="green-check-bordered text-left mb-0 pl-0">
									<li>
										Creating an environment for sales success – mindset and
										sales growth culture.
									</li>
									<li>Understanding your client and competition.</li>
									<li>
										Creating winning sales strategies – go to market, channel
										strategy, geographic understanding, international approach.
									</li>
									<li>Sales team structure and hiring for top performance.</li>
									<li>
										How to rapidly build your pipeline – proven “Best in Class”
										lead generation methods.
									</li>
									<li>
										Developing business, territory and individual sales plans.
									</li>
									<li>Driving sales team performance.</li>
								</ul>
							</div>
							<div className="col-md-6">
								<ul className="green-check-bordered text-left mb-0 pl-0">
									<li>Dealing with poor performance.</li>
									<li>How to develop a training and learning-based culture.</li>
									<li>How to coach your team for high performance.</li>
									<li>
										How to conduct highly effective sales meetings and 1:1
										coaching.
									</li>
									<li>
										Design and develop a sales process, utilize CRM and leverage
										current best in class sales tools.
									</li>
									<li>
										How to create accurate forecasts, success metrics and KPI’s
										to guide the business.
									</li>
									<li>
										Develop impactful compensation plans that motivate and drive
										results.
									</li>
								</ul>
							</div>
						</div>
					</div>
					<p className="mt-5">
						Certified Sales Leadership Program - We develop a program that
						allows your sales leader to receive a certification, giving you and
						them the confidence and knowledge that they have received the best
						training and coaching in the industry.Certified Sales Leadership
						Program - We develop a program that allows your sales leader to
						receive a certification, giving you and them the confidence and
						knowledge that they have received the best training and coaching in
						the industry.
					</p>
					<blockquote class="blockquote text-center mt-5">
						<p class="mb-0">
							“I am honored to have been chosen as Sales Xceleration Consultant
							and Certified Sales Leadership Trainer/Coach. These are all the
							tools and disciplines that I was taught and developed over my 28+
							year career. I am thrilled to be able give back and help
							accelerate the professional growth and effectiveness of our
							emerging sales leaders”
						</p>
						<footer class="text-secondary">
							Joe Dauskurdas{' '}
							<cite title="Source Title">– Founder ProGrowth Advisors</cite>
						</footer>
					</blockquote>
					<center className="my-4">
						<Link to="/about" className="btn btn-primary red-btn">
							Meet The Founder
						</Link>
					</center>
				</div>
			</div>
		</div>
	</Layout>
)
export default Genesis
